import React from 'react'
import { getDomain } from '../utils'
import './SearchResult.scss'

const SearchResult = ({ label, link, tags = [] }) => (
  <a href={link} className="SearchResult" target="_blank">
    <article>
      <div className="SearchResult_labelWrap">
        <h2 className="SearchResult_label">{label}</h2>
        <span className="SearchResult_domain">{getDomain(link)}</span>
      </div>
      <ul className="SearchResult_tags">
        {tags.map(tag => (
          <li key={`${label}-${tag.label}`} className="SearchResult_tag">
            {tag.label}
          </li>
        ))}
      </ul>
    </article>
  </a>
)

export default SearchResult
