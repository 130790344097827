import React from 'react'
import { getSearchResults, getSearchParams } from '../utils'
import SearchResult from '../components/SearchResult'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './search.scss'

const SearchPage = ({ pageContext: { newLinkNodes, vaultLinkNodes } }) => {
  const results = getSearchResults(newLinkNodes, vaultLinkNodes)
  const params = getSearchParams()

  return (
    <Layout>
      <Seo title="Search" />
      <main className="SearchPage">
        <div className="SearchPage_advice">
          <h5>How search works</h5>
          <ul>
            <li>Results include daily links and repo links</li>
            <li>
              You can search for (or append) tags like React, Javascript, UX.
              All links are tagged.
            </li>
            <li>Single letter words are ignored</li>
          </ul>
        </div>
        <p className="SearchPage_params">
          Showing search results for the terms:{' '}
          {params.map((term, index) => (
            <span key={`${term}-${index}`} className="SearchPage_params_term">
              {term}
            </span>
          ))}
        </p>

        {results.map(link => (
          <SearchResult key={link.label} {...link} />
        ))}
        {results.length === 0 && (
          <p>There are no results matching those terms</p>
        )}
      </main>
    </Layout>
  )
}

SearchPage.propTypes = {}

export default SearchPage
